import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DefaultInfoCard from "examples/Cards/InfoCards/AbsenCard";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useAuth } from "authContext";

function RiwayatAbsensi() {
  const { user } = useAuth();
  const [absen, setAbsen] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const formatDate = (date) => {
    const d = new Date(date);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
    };
    return d.toLocaleString("id-ID", options);
  };

  useEffect(() => {
    setLoading(true);
    fetch(`https://api.septoweb.com/${user.role}/presensi`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("septoken")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAbsen(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(dayjs(date));
  };

  const filteredAbsen = selectedDate
    ? absen.filter((item) => {
        const date = new Date(item.waktu);
        const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        const formattedSelected = dayjs(selectedDate).format("YYYY-M-D");

        return formattedDate === formattedSelected;
      })
    : absen;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="dark">
                  Riwayat Foto
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              {/* <DatePicker
                label="Filter by Date"
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
               */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Filter by Date"
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </LocalizationProvider>
            </Grid>
            {loading ? (
              <div>Loading...</div>
            ) : error ? (
              <div>Error</div>
            ) : (
              filteredAbsen.map((absenItem, index) => (
                <Grid key={index} item xs={12} md={6} xl={3}>
                  <DefaultInfoCard
                    icon="paypal"
                    title={absenItem.nama}
                    description={formatDate(absenItem.waktu)}
                    value={absenItem.lokasi}
                    image={absenItem.imageurl}
                    keterlambatan={absenItem.keterlambatan}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default RiwayatAbsensi;
