import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import MDBox from "components/MDBox";
import InputLabel from "@mui/material/InputLabel";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-seribu.jpeg";
import { FormControl, Menu } from "@mui/material";
import { useAuth } from "../../../authContext";

function Basic() {
  const { login } = useAuth();
  const navigate = useNavigate();

  const [rememberMe, setRememberMe] = useState(false);
  const [selectedRole, setSelectedRole] = useState("operator");
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    const result = await login(identifier, password, selectedRole);
    if (!result) {
      setError("Username atau password salah");
    } else {
      navigate("/dashboard", { replace: true });
    }
  };

  const identifierInput = (
    <MDInput
      type="number"
      label={selectedRole === "pjlp" ? "ID PJLP" : "NRK"}
      fullWidth
      value={identifier}
      onChange={(e) => setIdentifier(e.target.value)}
    />
  );

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Login
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            {error && (
              <MDBox mb={2} textAlign="center">
                {" "}
                <Alert severity="error">{error}</Alert>
              </MDBox>
            )}
            <MDBox mb={2}>
              <FormControl fullWidth>
                <InputLabel htmlFor="role-select">Role</InputLabel>
                <Select
                  labelId="role-select"
                  label="Pilih Role"
                  value={selectedRole}
                  onChange={handleRoleChange}
                  fullWidth
                  style={{ minHeight: 40 }}
                >
                  <MenuItem value="pjlp">PJLP</MenuItem>
                  <MenuItem value="operator">Operator</MenuItem>
                  <MenuItem value="pptk">PPTK</MenuItem>
                  <MenuItem value="ppk">PPK</MenuItem>
                  <MenuItem value="ppbj">PPBJ</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </Select>
              </FormControl>
            </MDBox>

            <MDBox mb={2}>{identifierInput}</MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                Sign In
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
