import React, { useEffect } from "react";
import { useAuth } from "./authContext";
import { useNavigate } from "react-router-dom";

const withProtection = (WrappedComponent, requiredRoles) => {
  const ProtectedComponent = (props) => {
    const { isAuthenticated, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
      if (!isAuthenticated) {
        navigate("/login");
      } else if (requiredRoles && !requiredRoles.includes(user.role)) {
        navigate("/");
      }
    }, [isAuthenticated, user, navigate, requiredRoles]);

    return isAuthenticated ? <WrappedComponent {...props} /> : null;
  };

  return ProtectedComponent;
};

export default withProtection;
