import { useRef, useState, useCallback, useEffect } from "react";
import { Button, Grid, Card, Typography, Box } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Webcam from "react-webcam";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "axios";
import { useAuth } from "authContext";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function Tables() {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [successAbsen, setSuccessAbsen] = useState(false);
  const [typeAbsen, setTypeAbsen] = useState("Absen Masuk");
  const APIkey = "1dde4573b7fc4e86bc9851f1334d936b";
  const { user } = useAuth();
  const [todayAbsen, setTodayAbsen] = useState({});

  const videoConstraints = {
    width: window.innerWidth,
    height: window.innerHeight,
    facingMode: "user",
  };

  const capture = useCallback(() => {
    try {
      const imageSrc = webcamRef.current.getScreenshot();
      setImgSrc(imageSrc);
    } catch (error) {
      console.error("Failed to capture image: ", error);
    }
  }, [webcamRef]);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [location, setLocation] = useState("");
  useEffect(() => {
    setSuccessAbsen(false);

    axios
      .get("https://api.septoweb.com/pjlp/presensi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("septoken")}`,
        },
      })
      .then((response) => {
        const data = response.data;
        const today = new Date().toLocaleDateString("id-ID", {
          day: "numeric",
          month: "long",
          year: "numeric",
        });
        console.log(data);

        const todayAbsen = data.filter((absen) => {
          const absenDate = new Date(absen.waktu);

          const absenDateString = absenDate.toLocaleDateString("id-ID", {
            day: "numeric",
            month: "long",
            year: "numeric",
          });
          return absenDateString === today && absen.keterangan === typeAbsen;
        });

        if (todayAbsen.length > 0) {
          setSuccessAbsen(true);
          setTodayAbsen(todayAbsen[0]);
          console.log(todayAbsen[0]);
        } else {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;
                fetch(
                  `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${APIkey}`
                )
                  .then((response) => response.json())
                  .then((data) => {
                    const components = data.results[0].components;

                    const kelurahan = components.suburb || components.neighbourhood;
                    const kota =
                      components.city || components.town || components.village || components.county;

                    const formattedLocation = `${kelurahan}, ${kota}`;

                    setLocation(formattedLocation);
                  })
                  .catch((error) => {
                    console.error("Failed to retrieve location details: ", error);
                  });
              },
              (error) => {
                console.error("Failed to retrieve location: ", error);
              }
            );
          } else {
            console.error("Geolocation is not supported by this browser.");
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [typeAbsen]);

  function toIsoString(date) {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? "+" : "-",
      pad = function (num) {
        return (num < 10 ? "0" : "") + num;
      };

    return (
      date.getFullYear() +
      "-" +
      pad(date.getMonth() + 1) +
      "-" +
      pad(date.getDate()) +
      "T" +
      pad(date.getHours()) +
      ":" +
      pad(date.getMinutes()) +
      ":" +
      pad(date.getSeconds()) +
      dif +
      pad(Math.floor(Math.abs(tzo) / 60)) +
      ":" +
      pad(Math.abs(tzo) % 60)
    );
  }

  const handleSubmit = async () => {
    try {
      const currentDate = new Date().toLocaleString("en-US", { timeZone: "Asia/Jakarta" });
      console.log(currentDate);

      const formData = new FormData();
      let byteString;
      if (imgSrc.split(",")[0].indexOf("base64") >= 0) byteString = atob(imgSrc.split(",")[1]);
      else byteString = unescape(imgSrc.split(",")[1]);

      let mimeString = imgSrc.split(",")[0].split(":")[1].split(";")[0];

      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      let blob = new Blob([ab], { type: mimeString });

      formData.append("file", blob, "absen.jpg");

      formData.append("user_id", user.id);
      formData.append("waktu", toIsoString(new Date()));
      formData.append("lokasi", location);
      formData.append("keterangan", typeAbsen);

      await axios.post("https://api.septoweb.com/pjlp/presensi", formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("septoken")}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setTodayAbsen({
        user_id: user.id,
        waktu: currentDate,
        lokasi: location,
        keterangan: typeAbsen,
        imageurl: imgSrc,
      });

      setSuccessAbsen(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="dark">
                  Absensi
                </MDTypography>
                <MDBox pt={3}>
                  <div
                    className="container"
                    style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                  >
                    {imgSrc || successAbsen ? (
                      <img
                        src={imgSrc || `https://api.septoweb.com${todayAbsen.imageurl}`}
                        alt="webcam"
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    ) : (
                      <Webcam
                        style={{ maxWidth: "100%", height: "auto" }}
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                      />
                    )}
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-label">Absen</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={typeAbsen}
                        label="Absen"
                        onChange={(e) => setTypeAbsen(e.target.value)}
                        sx={{
                          color: "black !important",
                          backgroundColor: "white !important",
                        }}
                      >
                        <MenuItem value={"Absen Masuk"}>Absen Masuk</MenuItem>
                        <MenuItem value={"Absen Keluar"} disabled={!successAbsen}>
                          Absen Keluar
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {!successAbsen ? (
                      location ? (
                        <>
                          <Button
                            variant="contained"
                            color="dark"
                            onClick={imgSrc ? () => setImgSrc(null) : capture}
                            style={{ marginTop: "1em" }}
                          >
                            {imgSrc ? "Retake photo" : "Capture photo"}
                          </Button>
                        </>
                      ) : (
                        <Typography variant="h6" color="dark" align="center" sx={{ mt: 3 }}>
                          Tolong nyalakan GPS anda
                        </Typography>
                      )
                    ) : null}

                    {!successAbsen && imgSrc && (
                      <>
                        <Button
                          variant="contained"
                          color="dark"
                          onClick={handleSubmit}
                          style={{ marginTop: "1em" }}
                        >
                          {"Submit photo"}
                        </Button>
                      </>
                    )}
                  </div>
                </MDBox>
                {successAbsen && (
                  <MDBox pt={3}>
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                      <Typography variant="h6" color="dark" align="center">
                        Absen Telah Berhasil
                      </Typography>
                      <Box mt={2}>
                        <CheckCircleOutlineIcon color="dark" fontSize="large" />
                      </Box>
                      <Typography variant="body1" color="dark" align="center">
                        {typeAbsen}
                      </Typography>
                      <Typography variant="body1" color="dark" align="center">
                        {new Date(todayAbsen.waktu).toLocaleString("id-ID", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </Typography>
                      <Typography variant="body1" color="dark" align="center">
                        {todayAbsen.keterlambatan}
                      </Typography>
                      <Typography variant="body1" color="dark" align="center">
                        {todayAbsen.lokasi}
                      </Typography>
                    </Grid>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
