import React, { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";

// axios
import axios from "axios";

// Web Absensi components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";

// Web Absensi example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import authorsTableData from "layouts/ketidakhadiran/data/authorsTableData";
import { Menu } from "@mui/material";

import { useAuth } from "../../authContext";

function Tables() {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [listAktifitas, setListAktifitas] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [alasan, setAlasan] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [editUserData, setEditUserData] = useState({});
  const [waktu, setWaktu] = useState("");

  const { user } = useAuth();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditOpen = (user) => {
    setEditUserData(user);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditUserData({});
    setEditOpen(false);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleEdit = () => {
    handleEditClose();

    const Header = {
      Authorization: `Bearer ${localStorage.getItem("septoken")}`,
    };
    const data = {
      id: editUserData.id,
      user_id: user.id,
      waktu: formatDate(editUserData.waktu),
      alasan: editUserData.alasan,
      keterangan: editUserData.keterangan,
    };
    axios
      .put(`https://api.septoweb.com/pjlp/ketidakhadiran`, data, { headers: Header })
      .then((res) => {
        console.log(res);
        setToggle(!toggle);
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      });
  };

  const handleAdd = () => {
    handleClose();
    const Header = {
      Authorization: `Bearer ${localStorage.getItem("septoken")}`,
    };
    const data = {
      user_id: user.id,
      waktu,
      alasan,
      keterangan,
    };
    axios
      .post("https://api.septoweb.com/pjlp/ketidakhadiran", data, { headers: Header })
      .then((res) => {
        console.log(res);
        setToggle(!toggle);
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      });
  };

  useEffect(() => {
    const Header = {
      Authorization: `Bearer ${localStorage.getItem("septoken")}`,
    };
    axios
      .get("https://api.septoweb.com/pjlp/ketidakhadiran", { headers: Header })
      .then((res) => {
        setListAktifitas(res.data);
        const { columns, rows } = authorsTableData(res.data, handleEditOpen);
        setColumns(columns);
        setRows(rows);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [toggle]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <MDBox>
              <MDButton onClick={handleClickOpen} color="dark" size="small">
                Tambah Ketidakhadiran
              </MDButton>
            </MDBox>
          </Grid>
          {error && (
            <Grid item xs={6}>
              <MDBox>
                <MDAlert color="error" dismissible>
                  <MDTypography variant="subtitle" fontWeight="regular" color="white">
                    {`Gagal menambahkan aktifitas!`}
                  </MDTypography>
                </MDAlert>
              </MDBox>
            </Grid>
          )}
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="dark">
                  Data Ketidakhadiran
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {listAktifitas.length > 0 && (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Tambah Ketidakhadiran</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="waktu"
              type="date"
              fullWidth
              value={waktu || ""}
              variant="outlined"
              onChange={(e) => setWaktu(e.target.value)}
            />

            <TextField
              margin="dense"
              id="alasan"
              label="alasan"
              type="text"
              multiline
              fullWidth
              onChange={(e) => setAlasan(e.target.value)}
            />
            <FormControl fullWidth>
              <InputLabel htmlFor="role-select">Keterangan</InputLabel>
              <Select
                labelId="role-select"
                label="Pilih Keterangan"
                onChange={(e) => setKeterangan(e.target.value)}
                fullWidth
                style={{ minHeight: 40 }}
              >
                <MenuItem value="Sakit">Sakit</MenuItem>
                <MenuItem value="Izin">Izin</MenuItem>
                <MenuItem value="Cuti">Cuti</MenuItem>
              </Select>
            </FormControl>
            {/* <TextField
              margin="dense"
              id="keterangan"
              label="Keterangan"
              rows={4}
              type="text"
              fullWidth
              onChange={(e) => setKeterangan(e.target.value)}
            /> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAdd} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={editOpen} onClose={handleEditClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Edit Pengguna</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="waktu"
              label="Waktu"
              type="date"
              fullWidth
              value={formatDate(editUserData.waktu) || ""}
              variant="outlined"
              onChange={(e) => setEditUserData({ ...editUserData, waktu: e.target.value })}
            />

            <TextField
              margin="dense"
              id="alasan"
              label="Alasan"
              type="text"
              multiline
              value={editUserData.alasan || ""}
              fullWidth
              onChange={(e) => setEditUserData({ ...editUserData, alasan: e.target.value })}
            />
            <TextField
              margin="dense"
              id="keterangan"
              label="Keterangan"
              rows={4}
              type="text"
              value={editUserData.keterangan || ""}
              fullWidth
              onChange={(e) => setEditUserData({ ...editUserData, keterangan: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEdit} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
