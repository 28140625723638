import React, { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";

// axios
import axios from "axios";

// Web Absensi components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";

// Web Absensi example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import authorsTableData from "layouts/operator/data/tinjauanData";
import { Menu } from "@mui/material";

import { useAuth } from "../../authContext";

function Tables() {
  const [listDataBaru, setListData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const handleEditOpen = (user) => {
    setEditUserData(user);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditUserData({});
    setEditOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const header = {
          Authorization: `Bearer ${localStorage.getItem("septoken")}`,
        };

        const response1 = await axios.get("https://api.septoweb.com/operator/ketidakhadiran", {
          headers: header,
        });

        const filteredData1 = response1.data.filter((item) => item.status === "terkirim");

        const response2 = await axios.get("https://api.septoweb.com/operator/presensi", {
          headers: header,
        });

        const filteredData2 = response2.data.filter((item) => item.status === "terkirim");

        const response3 = await axios.get("https://api.septoweb.com/operator/aktifitas", {
          headers: header,
        });

        const filteredData3 = response3.data.filter((item) => item.status === "terkirim");

        const combinedData = [...filteredData1, ...filteredData2, ...filteredData3];

        setListData(combinedData);

        const { columns, rows } = authorsTableData(combinedData, handleEditOpen);

        setColumns(columns);
        setRows(rows);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="dark">
                  Data Tinjauan
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {listDataBaru.length > 0 && (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
