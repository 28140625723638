/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Web Absensi components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import MDButton from "components/MDButton";
import { Typography } from "@mui/material";

export default function data(data, handleEditOpen) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Image = ({ src }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={src} alt="user photo" />
    </MDBox>
  );

  const Job = ({ title }) => <Typography variant="h6">{title}</Typography>;

  const formatDate = (date) => {
    const d = new Date(date);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return d.toLocaleDateString(undefined, options);
  };

  const rows = data.map((user) => ({
    hidden: user.nama,
    nama: <Job title={user.nama} />,
    email: <Job title={user.email} />,
    id: <Job title={user.no_nip} />,
    foto: <Image src={`https://api.septoweb.com${user.imageurl}`} />,
    waktu: <Job title={formatDate(user.waktu)} />,
    lokasi: <Job title={user.lokasi} />,
    keterangan: <Job title={user.keterangan} />,
  }));

  const columns = [
    { Header: "hidden", accessor: "hidden", align: "left", show: false },
    { Header: "nama", accessor: "nama", width: "45%", align: "left" },
    { Header: "email", accessor: "email", align: "left" },
    { Header: "id pjlp", accessor: "id", align: "left" },
    { Header: "foto", accessor: "foto", align: "left" },
    { Header: "waktu", accessor: "waktu", align: "left" },
    { Header: "lokasi", accessor: "lokasi", align: "center" },
    { Header: "keterangan", accessor: "keterangan", align: "center" },
  ];
  return { columns, rows };
}
