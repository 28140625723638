import React, { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";

// axios
import axios from "axios";

// Web Absensi components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";

// Web Absensi example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import authorsTableData from "layouts/penggunapjlp/data/authorsTableData";
import { Menu } from "@mui/material";

function Tables() {
  const [open, setOpen] = useState(false);
  const [jabatan, setJabatan] = useState("");
  const [status, setStatus] = useState("");
  const [nama, setNama] = useState("");
  const [nip, setNip] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [listUser, setListUser] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [editUserData, setEditUserData] = useState({});
  const [editPassword, setEditPassword] = useState("");
  const [telepon, setTelepon] = useState("");
  const [data_nik, setDataNik] = useState("");
  const [data_kk, setDataKk] = useState("");
  const [no_kontrak, setNoKontrak] = useState("");
  const [mulaiKontrak, setMulaiKontrak] = useState("");
  const [akhirKontrak, setAkhirKontrak] = useState("");
  const [data_npwp, setDataNpwp] = useState("");
  const [no_rek, setNoRek] = useState("");
  const [no_kesehatan, setNoKesehatan] = useState("");
  const [no_ketenagakerjaan, setNoKetenagakerjaan] = useState("");
  const [listJabatan, setListJabatan] = useState([]);

  const [statusPerkawinan, setStatusPerkawinan] = useState("");

  const [openJabatan, setOpenJabatan] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [dialogJabatan, setDialogJabatan] = useState("");
  const [currentJabatan, setCurrentJabatan] = useState(0);
  const [currentId, setCurrentId] = useState(0);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setJabatan("");
    setStatus("");
    setNama("");
    setNip("");
    setEmail("");
    setPassword("");
    setError(false);
    setTelepon("");
    setDataNik("");
    setDataKk("");
    setNoKontrak("");
    setMulaiKontrak("");
    setAkhirKontrak("");
    setDataNpwp("");
    setNoRek("");
    setNoKesehatan("");
    setNoKetenagakerjaan("");

    setOpen(false);
  };

  const handleEditOpen = (user, status) => {
    setEditUserData(user);
    if (status === "edit") {
      setCurrentJabatan(user.jabatan_id);
      setEditOpen(true);
    } else {
      // const Header = {
      //   Authorization: `Bearer ${localStorage.getItem("septoken")}`,
      // };
      // axios
      //   .delete(`https://api.septoweb.com/ppbj/pjlp/${user.id}`, { headers: Header })
      //   .then((res) => {
      //     console.log(res);
      //     setToggle(!toggle);
      //   })
      //   .catch((error) => {
      //     setError(true);
      //     console.log(error);
      //   });
      setCurrentId(user.id);
      setOpenDelete(true);
    }
  };

  const handleDelete = (id) => {
    const Header = {
      Authorization: `Bearer ${localStorage.getItem("septoken")}`,
    };
    axios
      .delete(`https://api.septoweb.com/ppbj/pjlp/${id}`, { headers: Header })
      .then((res) => {
        console.log(res);
        setToggle(!toggle);
        setOpenDelete(false);
      })
      .catch((error) => {
        setError(true);
        console.log(error);
        setOpenDelete(false);
      });
  };

  const handleEditClose = () => {
    setEditUserData({});
    setEditOpen(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleJabatanOpen = () => {
    setOpenJabatan(true);
  };

  const handleJabatanClose = () => {
    setOpenJabatan(false);
  };

  const handleEdit = () => {
    handleEditClose();
    const Header = {
      Authorization: `Bearer ${localStorage.getItem("septoken")}`,
    };
    const data = {
      id: editUserData.id,
      no_nip: editUserData.no_nip,
      nama: editUserData.nama,
      email: editUserData.email,
      password: editPassword,
      jabatan: currentJabatan,
      status: editUserData.status,
      telepon: editUserData.telepon,
      data_nik: editUserData.data_nik,
      data_kk: editUserData.data_kk,
      no_kontrak: editUserData.no_kontrak,
      mulai_kontrak: editUserData.mulai_kontrak,
      akhir_kontrak: editUserData.akhir_kontrak,
      data_npwp: editUserData.data_npwp,
      no_rek: editUserData.no_rek,
      no_kesehatan: editUserData.no_kesehatan,
      no_ketenagakerjaan: editUserData.no_ketenagakerjaan,
    };
    console.log(data);
    axios
      .put(`https://api.septoweb.com/ppbj/pjlp`, data, { headers: Header })
      .then((res) => {
        console.log(res);
        setToggle(!toggle);
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      });
  };

  const handleAdd = () => {
    handleClose();
    const Header = {
      Authorization: `Bearer ${localStorage.getItem("septoken")}`,
    };
    const data = {
      no_nip: nip,
      nama: nama,
      email: email,
      password: password,
      jabatan: jabatan,
      status: status,
      telepon: telepon,
      data_nik: data_nik,
      data_kk: data_kk,
      no_kontrak: no_kontrak,
      mulai_kontrak: mulaiKontrak,
      akhir_kontrak: akhirKontrak,
      data_npwp: data_npwp,
      no_rek: no_rek,
      no_kesehatan: no_kesehatan,
      no_ketenagakerjaan: no_ketenagakerjaan,
    };
    axios
      .post("https://api.septoweb.com/ppbj/pjlp", data, { headers: Header })
      .then((res) => {
        console.log(res);
        setToggle(!toggle);
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      });
  };

  const handleAddJabatan = () => {
    handleJabatanClose();
    const Header = {
      Authorization: `Bearer ${localStorage.getItem("septoken")}`,
    };
    const data = {
      jabatan: dialogJabatan,
    };
    axios
      .post("https://api.septoweb.com/ppbj/jabatan", data, { headers: Header })
      .then((res) => {
        console.log(res);
        setToggle(!toggle);
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      });
  };

  useEffect(() => {
    const Header = {
      Authorization: `Bearer ${localStorage.getItem("septoken")}`,
    };

    const listJabatan = [];

    axios
      .get("https://api.septoweb.com/ppbj/jabatan", { headers: Header })
      .then((res) => {
        setListJabatan(res.data);
        res.data.map((jabatan) => {
          listJabatan.push({
            id: jabatan.id,
            jabatan: jabatan.jabatan,
          });
        });
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("https://api.septoweb.com/ppbj/pjlp", { headers: Header })
      .then((res) => {
        setListUser(res.data);
        const { columns, rows } = authorsTableData(res.data, handleEditOpen);
        setColumns(columns);
        setRows(rows);

        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [toggle]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="dark">
                Jumlah Pengguna
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Jumlah PJLP"
                count={listUser.length}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Master Jabatan"
                count={listJabatan.length}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12}>
            <MDBox>
              <MDButton onClick={handleClickOpen} color="dark" size="small" sx={{ mr: 1 }}>
                Tambah PJLP
              </MDButton>
              <MDButton onClick={handleJabatanOpen} color="dark" size="small">
                Tambah Jabatan
              </MDButton>
            </MDBox>
          </Grid>
          {error && (
            <Grid item xs={6}>
              <MDBox>
                <MDAlert color="error" dismissible>
                  <MDTypography variant="subtitle" fontWeight="regular" color="white">
                    {`Gagal menambahkan pengguna!`}
                  </MDTypography>
                </MDAlert>
              </MDBox>
            </Grid>
          )}
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="dark">
                  Data Pengguna
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {listUser.length > 0 && (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted
                    entriesPerPage
                    showTotalEntries
                    canSearch
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Tambah Pengguna</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="no_nip"
              label="No PJLP"
              type="text"
              fullWidth
              value={nip}
              onChange={(e) => setNip(e.target.value)}
            />
            <TextField
              margin="dense"
              id="nama"
              label="Nama"
              type="text"
              fullWidth
              value={nama}
              onChange={(e) => setNama(e.target.value)}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel id="jabatan-label">Jabatan</InputLabel>
              <Select
                labelId="jabatan-label"
                id="jabatan"
                value={jabatan}
                label="Jabatan"
                onChange={(event) => setJabatan(event.target.value)}
                style={{ minHeight: 40 }}
              >
                {listJabatan.map((jabatan) => (
                  <MenuItem key={jabatan.id} value={jabatan.id}>
                    {jabatan.jabatan}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              id="email"
              label="Email"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              margin="dense"
              id="telepon"
              label="Telepon"
              type="text"
              fullWidth
              value={telepon}
              onChange={(e) => setTelepon(e.target.value)}
            />
            <TextField
              margin="dense"
              id="data_nik"
              label="Data NIK"
              type="text"
              fullWidth
              value={data_nik}
              onChange={(e) => setDataNik(e.target.value)}
            />
            <TextField
              margin="dense"
              id="data_kk"
              label="Data KK"
              type="text"
              fullWidth
              value={data_kk}
              onChange={(e) => setDataKk(e.target.value)}
            />
            <TextField
              margin="dense"
              id="no_kontrak"
              label="No Kontrak"
              type="text"
              fullWidth
              value={no_kontrak}
              onChange={(e) => setNoKontrak(e.target.value)}
            />

            <TextField
              margin="dense"
              id="mulai_kontrak"
              label="Mulai Kontrak"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={mulaiKontrak ? mulaiKontrak.toISOString().split("T")[0] : ""} // Convert date to 'yyyy-MM-dd'
              onChange={(event) => setMulaiKontrak(new Date(event.target.value))}
            />

            <TextField
              margin="dense"
              id="akhir_kontrak"
              label="Akhir Kontrak"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={akhirKontrak ? akhirKontrak.toISOString().split("T")[0] : ""} // Convert date to 'yyyy-MM-dd'
              onChange={(event) => setAkhirKontrak(new Date(event.target.value))}
            />
            <TextField
              margin="dense"
              id="data_npwp"
              label="Data NPWP"
              type="text"
              fullWidth
              value={data_npwp}
              onChange={(e) => setDataNpwp(e.target.value)}
            />
            <TextField
              margin="dense"
              id="no_rek"
              label="No Rek"
              type="text"
              fullWidth
              value={no_rek}
              onChange={(e) => setNoRek(e.target.value)}
            />
            <TextField
              margin="dense"
              id="no_kesehatan"
              label="No Kesehatan"
              type="text"
              fullWidth
              value={no_kesehatan}
              onChange={(e) => setNoKesehatan(e.target.value)}
            />
            <TextField
              margin="dense"
              id="no_ketenagakerjaan"
              label="No Ketenagakerjaan"
              type="text"
              fullWidth
              value={no_ketenagakerjaan}
              onChange={(e) => setNoKetenagakerjaan(e.target.value)}
            />

            <FormControl fullWidth margin="dense">
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                value={status}
                label="Status"
                onChange={(event) => setStatus(event.target.value)}
                style={{ minHeight: 40 }}
              >
                <MenuItem value={1}>Aktif</MenuItem>
                <MenuItem value={0}>Tidak Aktif</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAdd} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDelete} onClose={handleCloseDelete} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Hapus Pengguna</DialogTitle>
          <DialogContent>
            <Typography variant="body1" color="dark">
              Apakah anda yakin ingin menghapus pengguna ini?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleDelete(currentId)} color="primary">
              Hapus
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={editOpen} onClose={handleEditClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Edit Pengguna</DialogTitle>
          <DialogContent>
            <TextField
              disabled
              margin="dense"
              id="id"
              label="ID"
              type="text"
              fullWidth
              value={editUserData.id}
            />
            <TextField
              margin="dense"
              id="no_nip"
              label="No PJLP"
              type="text"
              fullWidth
              value={editUserData.no_nip}
              onChange={(e) => setEditUserData({ ...editUserData, no_nip: e.target.value })}
            />
            <TextField
              margin="dense"
              id="nama"
              label="Nama"
              type="text"
              fullWidth
              value={editUserData.nama}
              onChange={(e) => setEditUserData({ ...editUserData, nama: e.target.value })}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel id="jabatan-label">Jabatan</InputLabel>
              <Select
                labelId="jabatan-label"
                id="jabatan"
                value={currentJabatan}
                label="Jabatan"
                onChange={(event) => setCurrentJabatan(event.target.value)}
                style={{ minHeight: 40 }}
              >
                {listJabatan.map((jabatan) => (
                  <MenuItem key={jabatan.id} value={jabatan.id}>
                    {jabatan.jabatan}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              id="email"
              label="Email"
              type="email"
              fullWidth
              value={editUserData.email}
              onChange={(e) => setEditUserData({ ...editUserData, email: e.target.value })}
            />
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              value={editPassword}
              onChange={(e) => setEditPassword(e.target.value)}
            />
            <TextField
              margin="dense"
              id="telepon"
              label="Telepon"
              type="text"
              fullWidth
              value={editUserData.telepon}
              onChange={(e) => setEditUserData({ ...editUserData, telepon: e.target.value })}
            />
            <TextField
              margin="dense"
              id="data_nik"
              label="Data NIK"
              type="text"
              fullWidth
              value={editUserData.data_nik}
              onChange={(e) => setEditUserData({ ...editUserData, data_nik: e.target.value })}
            />
            <TextField
              margin="dense"
              id="data_kk"
              label="Data KK"
              type="text"
              fullWidth
              value={editUserData.data_kk}
              onChange={(e) => setEditUserData({ ...editUserData, data_kk: e.target.value })}
            />
            <TextField
              margin="dense"
              id="no_kontrak"
              label="No Kontrak"
              type="text"
              fullWidth
              value={editUserData.no_kontrak}
              onChange={(e) => setEditUserData({ ...editUserData, no_kontrak: e.target.value })}
            />
            <TextField
              margin="dense"
              id="mulai_kontrak"
              label="Mulai Kontrak"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={formatDate(editUserData.mulai_kontrak)}
              onChange={(event) =>
                setEditUserData({
                  ...editUserData,
                  mulai_kontrak: event.target.value ? formatDate(event.target.value) : null,
                })
              }
            />
            <TextField
              margin="dense"
              id="akhir_kontrak"
              label="Akhir Kontrak"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={formatDate(editUserData.akhir_kontrak)}
              onChange={(event) =>
                setEditUserData({
                  ...editUserData,
                  akhir_kontrak: event.target.value ? formatDate(event.target.value) : null,
                })
              }
            />

            <TextField
              margin="dense"
              id="no_rek"
              label="No Rek"
              type="text"
              fullWidth
              value={editUserData.no_rek}
              onChange={(e) => setEditUserData({ ...editUserData, no_rek: e.target.value })}
            />
            <TextField
              margin="dense"
              id="no_kesehatan"
              label="No Kesehatan"
              type="text"
              fullWidth
              value={editUserData.no_kesehatan}
              onChange={(e) => setEditUserData({ ...editUserData, no_kesehatan: e.target.value })}
            />
            <TextField
              margin="dense"
              id="no_ketenagakerjaan"
              label="No Ketenagakerjaan"
              type="text"
              fullWidth
              value={editUserData.no_ketenagakerjaan}
              onChange={(e) =>
                setEditUserData({ ...editUserData, no_ketenagakerjaan: e.target.value })
              }
            />
            <FormControl fullWidth margin="dense">
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                value={editUserData.status}
                label="Status"
                onChange={(event) =>
                  setEditUserData({ ...editUserData, status: event.target.value })
                }
                style={{ minHeight: 40 }}
              >
                <MenuItem value={1}>Aktif</MenuItem>
                <MenuItem value={0}>Tidak Aktif</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEdit} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openJabatan} onClose={handleJabatanClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Tambah Jabatan</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="jabatan"
              label="Jabatan"
              type="text"
              fullWidth
              value={dialogJabatan}
              onChange={(e) => setDialogJabatan(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleJabatanClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddJabatan} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
