import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import TextField from "@mui/material/TextField";
import MDButton from "components/MDButton";
import axios from "axios";

function RiwayatAbsensi() {
  const [masterJam, setMasterJam] = useState([]);

  useEffect(() => {
    fetch("https://api.septoweb.com/admin/jamkerja", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("septoken")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setMasterJam(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleInputChange = (e, index, field) => {
    const { value } = e.target;
    const updatedMasterJam = [...masterJam];
    updatedMasterJam[index][field] = value;
    setMasterJam(updatedMasterJam);
  };

  const handleEdit = (id) => {
    const data = masterJam.find((item) => item.id === id);
    axios
      .put(
        "https://api.septoweb.com/admin/jamkerja",
        {
          jam_masuk: data.jam_masuk,
          jam_pulang: data.jam_pulang,
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("septoken")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="dark">
                  Master Jam
                </MDTypography>
              </MDBox>

              <MDBox mt={3}>
                <Grid container spacing={1}>
                  {masterJam.map((item, index) => (
                    <React.Fragment key={index}>
                      <Grid item md={1} xs={12}>
                        <MDTypography
                          variant="h6"
                          color="dark"
                          sx={{
                            p: 0,
                            textAlign: "center",
                            textAlign: { xs: "left", md: "center" },
                            mt: { xs: 0, md: 2 },
                          }}
                        >
                          {item.hari}:
                        </MDTypography>
                      </Grid>
                      <Grid item md={5} xs={6}>
                        <TextField
                          margin="dense"
                          id="jam_masuk"
                          type="text"
                          fullWidth
                          value={item.jam_masuk}
                          onChange={(e) => handleInputChange(e, index, "jam_masuk")}
                        />
                      </Grid>
                      <Grid item md={5} xs={6}>
                        <TextField
                          margin="dense"
                          id="jam_pulang"
                          type="text"
                          value={item.jam_pulang}
                          fullWidth
                          onChange={(e) => handleInputChange(e, index, "jam_pulang")}
                        />
                      </Grid>
                      <Grid item md={1} xs={12}>
                        <MDButton
                          variant="gradient"
                          color="dark"
                          onClick={() => handleEdit(item.id)}
                        >
                          Edit
                        </MDButton>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default RiwayatAbsensi;
