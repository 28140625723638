import React, { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";

// axios
import axios from "axios";

// Web Absensi components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";

// Web Absensi example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import authorsTableData from "layouts/pengguna/data/authorsTableData";
import { Menu } from "@mui/material";

function Tables() {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");
  const [nama, setNama] = useState("");
  const [nip, setNip] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [listUser, setListUser] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [editUserData, setEditUserData] = useState({});
  const [editPassword, setEditPassword] = useState("");
  const [currentId, setCurrentId] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setRole("");
    setStatus("");
    setNama("");
    setNip("");
    setEmail("");
    setPassword("");

    setOpen(false);
  };

  const handleDelete = (id) => {
    const Header = {
      Authorization: `Bearer ${localStorage.getItem("septoken")}`,
    };
    axios
      .delete(`https://api.septoweb.com/admin/users/${id}`, { headers: Header })
      .then((res) => {
        console.log(res);
        setToggle(!toggle);
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      })
      .finally(() => {
        setDeleteOpen(false);
      });
  };

  const handleEditOpen = (user, status) => {
    setEditUserData(user);
    if (status === "edit") {
      setEditOpen(true);
    } else {
      setDeleteOpen(true);
      setCurrentId(user.id);
    }
  };

  const handleEditClose = () => {
    setEditUserData({});
    setEditOpen(false);
  };

  const handleEdit = () => {
    handleEditClose();
    const Header = {
      Authorization: `Bearer ${localStorage.getItem("septoken")}`,
    };
    const data = {
      id: editUserData.id,
      nip: editUserData.nip,
      nama: editUserData.nama,
      email: editUserData.email,
      password: editPassword,
      role: editUserData.role,
      status: editUserData.status,
    };
    console.log(data);
    axios
      .put(`https://api.septoweb.com/admin/users`, data, { headers: Header })
      .then((res) => {
        console.log(res);
        setToggle(!toggle);
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      });
  };

  const handleAdd = () => {
    handleClose();
    console.log(role, status, nama, nip, email, password);
    const Header = {
      Authorization: `Bearer ${localStorage.getItem("septoken")}`,
    };
    const data = {
      nip: nip,
      nama: nama,
      email: email,
      password: password,
      role: role,
      status: status,
    };
    axios
      .post("https://api.septoweb.com/admin/users", data, { headers: Header })
      .then((res) => {
        console.log(res);
        setToggle(!toggle);
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      });
  };

  useEffect(() => {
    const Header = {
      Authorization: `Bearer ${localStorage.getItem("septoken")}`,
    };
    axios
      .get("https://api.septoweb.com/admin/users", { headers: Header })
      .then((res) => {
        setListUser(res.data);
        const { columns, rows } = authorsTableData(res.data, handleEditOpen);
        setColumns(columns);
        setRows(rows);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [toggle]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="dark">
                Jumlah Pengguna
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Operator"
                count={listUser.filter((user) => user.role === "operator").length}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="PPTK"
                count={listUser.filter((user) => user.role === "pptk").length}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="PPK"
                count={listUser.filter((user) => user.role === "ppk").length}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="PPBJ"
                count={listUser.filter((user) => user.role === "ppbj").length}
              />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox>
              <MDButton onClick={handleClickOpen} color="dark" size="small">
                Tambah Pengguna
              </MDButton>
            </MDBox>
          </Grid>
          {error && (
            <Grid item xs={6}>
              <MDBox>
                <MDAlert color="error" dismissible>
                  <MDTypography variant="subtitle" fontWeight="regular" color="white">
                    {`Gagal menambahkan pengguna!`}
                  </MDTypography>
                </MDAlert>
              </MDBox>
            </Grid>
          )}
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="dark">
                  Data Pengguna
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {listUser.length > 0 && (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted
                    entriesPerPage
                    showTotalEntries
                    canSearch
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Tambah Pengguna</DialogTitle>
          <DialogContent>
            <FormControl fullWidth margin="dense">
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                id="role"
                value={role}
                label="Role"
                onChange={(event) => setRole(event.target.value)}
                style={{ minHeight: 40 }}
              >
                <MenuItem value={"operator"}>Operator</MenuItem>
                <MenuItem value={"pptk"}>PPTK</MenuItem>
                <MenuItem value={"ppk"}>PPK</MenuItem>
                <MenuItem value={"ppbj"}>PPBJ</MenuItem>
                <MenuItem value={"admin"}>Admin</MenuItem>
              </Select>
            </FormControl>
            <TextField
              autoFocus
              margin="dense"
              id="nip"
              label="NIP"
              type="text"
              fullWidth
              value={nip}
              onChange={(e) => setNip(e.target.value)}
            />
            <TextField
              margin="dense"
              id="nama"
              label="Nama"
              type="text"
              fullWidth
              value={nama}
              onChange={(e) => setNama(e.target.value)}
            />
            <TextField
              margin="dense"
              id="email"
              label="Email"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <FormControl fullWidth margin="dense">
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                value={status}
                label="Status"
                onChange={(event) => setStatus(event.target.value)}
                style={{ minHeight: 40 }}
              >
                <MenuItem value={1}>Aktif</MenuItem>
                <MenuItem value={0}>Tidak Aktif</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAdd} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          aria-labelledby="delete-dialog"
        >
          <DialogTitle id="delete-dialog">Hapus Pengguna</DialogTitle>
          <DialogContent>
            <MDTypography variant="subtitle" fontWeight="regular" color="dark">
              {`Apakah anda yakin ingin menghapus pengguna ini?`}
            </MDTypography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleDelete(currentId)} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={editOpen} onClose={handleEditClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Edit Pengguna</DialogTitle>
          <DialogContent>
            <TextField
              disabled
              margin="dense"
              id="id"
              label="ID"
              type="text"
              fullWidth
              value={editUserData.id}
            />
            <TextField
              margin="dense"
              id="nip"
              label="NIP"
              type="text"
              fullWidth
              value={editUserData.nip}
              onChange={(e) => setEditUserData({ ...editUserData, nip: e.target.value })}
            />
            <TextField
              margin="dense"
              id="nama"
              label="Nama"
              type="text"
              fullWidth
              value={editUserData.nama}
              onChange={(e) => setEditUserData({ ...editUserData, nama: e.target.value })}
            />
            <TextField
              margin="dense"
              id="email"
              label="Email"
              type="email"
              fullWidth
              value={editUserData.email}
              onChange={(e) => setEditUserData({ ...editUserData, email: e.target.value })}
            />
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              value={editPassword}
              onChange={(e) => setEditPassword(e.target.value)}
            />

            <FormControl fullWidth margin="dense">
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                id="role"
                value={editUserData.role}
                label="Role"
                onChange={(event) => setEditUserData({ ...editUserData, role: event.target.value })}
                style={{ minHeight: 40 }}
              >
                <MenuItem value={"operator"}>Operator</MenuItem>
                <MenuItem value={"pptk"}>PPTK</MenuItem>
                <MenuItem value={"ppk"}>PPK</MenuItem>
                <MenuItem value={"ppbj"}>PPBJ</MenuItem>
                <MenuItem value={"admin"}>Admin</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                value={editUserData.status}
                label="Status"
                onChange={(event) =>
                  setEditUserData({ ...editUserData, status: event.target.value })
                }
                style={{ minHeight: 40 }}
              >
                <MenuItem value={1}>Aktif</MenuItem>
                <MenuItem value={0}>Tidak Aktif</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEdit} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
