import React, { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Web Absensi components
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";

// Web Absensi example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import { useAuth } from "authContext";
import axios from "axios";

function Dashboard() {
  const { user } = useAuth();
  const [dataPresensi, setDataPresensi] = useState([]);
  const [dataKetidakhadiran, setDataKetidakhadiran] = useState([]);
  const [dataAktifitas, setDataAktifitas] = useState([]);
  const [JumlahPengguna, setJumlahPengguna] = useState(0);
  const [JumlahPresensi, setJumlahPresensi] = useState(0);
  const [JumlahKetidakhadiran, setJumlahKetidakhadiran] = useState(0);
  const [JumlahTinjauan, setJumlahTinjauan] = useState(0);
  const [JumlahJabatan, setJumlahJabatan] = useState(0);
  const [JumlahData, setJumlahData] = useState(0);

  const roleCards = {
    pptk: [
      {
        title: "Daftar Tinjauan",
        icon: "weekend",
        count:
          dataPresensi.filter((item) => item.status === "tersubmit").length +
          dataKetidakhadiran.filter((item) => item.status === "tersubmit").length +
          dataAktifitas.filter((item) => item.status === "tersubmit").length,
      },
      {
        title: "Daftar butuh tinjauan",
        icon: "leaderboard",
        count:
          dataPresensi.filter((item) => item.status === "terkirim").length +
          dataKetidakhadiran.filter((item) => item.status === "terkirim").length +
          dataAktifitas.filter((item) => item.status === "terkirim").length,
      },
    ],
    ppbj: [
      {
        title: "PJLP",
        icon: "weekend",
        count: JumlahPengguna,
      },
      {
        title: "Master Jabatan",
        icon: "leaderboard",
        count: JumlahJabatan,
      },
    ],
    operator: [
      {
        title: "Presensi",
        icon: "weekend",
        count: dataPresensi.filter((item) => item.status === "pending").length,
      },
      {
        title: "Ketidakhadiran",
        icon: "Rekapitulasi",
        count: dataKetidakhadiran.filter((item) => item.status === "pending").length,
      },
    ],
    admin: [
      {
        title: "Pengguna",
        icon: "weekend",
        count: JumlahPengguna,
      },
      {
        title: "Absensi",
        icon: "Rekapitulasi",
        count: JumlahPresensi,
      },
    ],
    pjlp: [
      {
        title: "Kehadiran",
        icon: "Rekapitulasi",
        count: JumlahPresensi,
      },
      {
        title: "Ketidakhadiran",
        icon: "weekend",
        count: JumlahKetidakhadiran,
      },
    ],
    ppk: [
      {
        title: "Data",
        icon: "Rekapitulasi",
        count: JumlahData,
      },
      {
        title: "PJLP",
        icon: "weekend",
        count: JumlahPengguna,
      },
    ],
  };

  const fetchData = async (url, setCount) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("septoken")}`,
        },
      });
      setCount(response.data.length);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAndSetData = async (url, setData) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("septoken")}`,
        },
      });
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCount = async (url, setCount) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("septoken")}`,
        },
      });
      setCount(response.data.total);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user.role === "admin") {
      fetchData("https://api.septoweb.com/admin/users", setJumlahPengguna);
      fetchData("https://api.septoweb.com/admin/presensi", setJumlahPresensi);
    } else if (user.role === "pjlp") {
      fetchData("https://api.septoweb.com/pjlp/ketidakhadiran", setJumlahKetidakhadiran);
      fetchData("https://api.septoweb.com/pjlp/presensi", setJumlahPresensi);
    } else if (user.role === "pptk") {
      fetchAndSetData("https://api.septoweb.com/pptk/presensi", setDataPresensi);
      fetchAndSetData("https://api.septoweb.com/pptk/ketidakhadiran", setDataKetidakhadiran);
      fetchAndSetData("https://api.septoweb.com/pptk/aktifitas", setDataAktifitas);
    } else if (user.role === "ppbj") {
      fetchData("https://api.septoweb.com/ppbj/pjlp", setJumlahPengguna);
      fetchData("https://api.septoweb.com/ppbj/jabatan", setJumlahJabatan);
    } else if (user.role === "operator") {
      fetchAndSetData("https://api.septoweb.com/operator/presensi", setDataPresensi);
      fetchAndSetData("https://api.septoweb.com/operator/ketidakhadiran", setDataKetidakhadiran);
    } else if (user.role === "ppk") {
      fetchCount("https://api.septoweb.com/ppk/data", setJumlahData);
      fetchData("https://api.septoweb.com/ppk/pjlp", setJumlahPengguna);
    }
  }, [user.role]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDAlert color="info" dismissible>
              <MDTypography variant="h5" fontWeight="regular" color="dark">
                {`Selamat Datang, ${user.nama}!`}
              </MDTypography>
            </MDAlert>
          </Grid>

          {/* Render user-specific cards dynamically */}
          {roleCards[user.role]?.map((card, index) => (
            <Grid key={index} item xs={12} md={6} lg={6}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon={card.icon}
                  title={card.title}
                  count={card.count}
                />
              </MDBox>
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
