/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Web Absensi components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { Typography } from "@mui/material";

export default function data(data, handleEditOpen) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title }) => <Typography variant="h6">{title}</Typography>;

  const formatDate = (date) => {
    const d = new Date(date);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return d.toLocaleDateString(undefined, options);
  };

  const rows = data.map((user) => ({
    waktu: <Job title={formatDate(user.waktu)} />,
    alasan: <Job title={user.alasan} />,
    keterangan: <Job title={user.keterangan} />,
    action: (
      <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
        onClick={() => handleEditOpen(user)}
      >
        Edit
      </MDTypography>
    ),
  }));

  const columns = [
    { Header: "waktu", accessor: "waktu", align: "left" },
    { Header: "alasan", accessor: "alasan", align: "center" },
    { Header: "keterangan", accessor: "keterangan", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];
  return { columns, rows };
}
