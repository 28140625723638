import React, { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";

// axios
import axios from "axios";

// Web Absensi components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";

// Web Absensi example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import authorsTableData from "layouts/pptk/data/tinjauanData";
import dataPjlp from "layouts/pptk/data/dataPjlp";
import { Menu } from "@mui/material";

import { useAuth } from "../../authContext";

function Tables() {
  const [listDataBaru, setListData] = useState([]);
  const [listPjlp, setListPjlp] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [columnsPjlp, setColumnsPjlp] = useState([]);
  const [rowsPjlp, setRowsPjlp] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [editedRows, setEditedRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleEditClose = () => {
    setEditOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const header = {
          Authorization: `Bearer ${localStorage.getItem("septoken")}`,
        };

        const responsePjlp = await axios.get("https://api.septoweb.com/pptk/pjlp", {
          headers: header,
        });

        setListPjlp(responsePjlp.data);

        const { columnsPjlp, rowsPjlp } = dataPjlp(responsePjlp.data, handleEditOpen);

        setColumnsPjlp(columnsPjlp);
        setRowsPjlp(rowsPjlp);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleKirim = async () => {
    if (listDataBaru.length > 0) {
      try {
        const header = {
          Authorization: `Bearer ${localStorage.getItem("septoken")}`,
        };

        const response1 = await axios.put(
          `https://api.septoweb.com/pptk/tinjauan/${listDataBaru[0].user_id}`,
          {},
          {
            headers: header,
          }
        );

        setEditOpen(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setEditOpen(false);
      }
    }
  };

  const handleEditOpen = async (user) => {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("septoken")}`,
    };

    const response1 = await axios.get("https://api.septoweb.com/pptk/ketidakhadiran", {
      headers: header,
    });

    const filteredData1 = response1.data.filter((item) => item.status === "terkirim");

    const response2 = await axios.get("https://api.septoweb.com/pptk/presensi", {
      headers: header,
    });

    const filteredData2 = response2.data.filter((item) => item.status === "terkirim");

    const response3 = await axios.get("https://api.septoweb.com/pptk/aktifitas", {
      headers: header,
    });

    const filteredData3 = response3.data.filter((item) => item.status === "pending");

    const combinedData = [...filteredData1, ...filteredData2, ...filteredData3];

    const { columns, rows } = authorsTableData(
      combinedData.filter((item) => item.no_nip === user.no_nip)
    );
    setListData(combinedData.filter((item) => item.no_nip === user.no_nip));
    setColumns(columns);
    setEditedRows(rows);
    setEditOpen(true);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="dark">
                  Data Tinjauan
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {listPjlp.length > 0 && (
                  <DataTable
                    table={{ columns: columnsPjlp, rows: rowsPjlp }}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    canSearch
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        <Dialog
          open={editOpen}
          onClose={handleEditClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="xl"
        >
          <DialogTitle id="form-dialog-title">List Tinjauan</DialogTitle>
          <DialogContent>
            <DataTable
              table={{ columns: columns, rows: editedRows }}
              isSorted={true}
              showTotalEntries={false}
              canSearch
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="primary">
              Cancel
            </Button>
            <Button color="primary" onClick={handleKirim}>
              Kirim
            </Button>
          </DialogActions>
        </Dialog>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
