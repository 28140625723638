import React, { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";

// axios
import axios from "axios";

// Web Absensi components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";

// Web Absensi example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import authorsTableData from "layouts/operator/data/tableHadir";
import { Menu } from "@mui/material";

import { useAuth } from "../../authContext";

function Tables() {
  const [error, setError] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [listAktifitas, setListAktifitas] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  const handleKirimAll = () => {
    console.log(rows);

    const updatePresensi = (url) => {
      return axios.put(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("septoken")}`,
          },
        }
      );
    };

    const promises = rows.map((item) => {
      const url = `https://api.septoweb.com/operator/presensi/${item.hiddenId}`;
      const urlMasuk = `https://api.septoweb.com/operator/presensi/${item.hiddenIdMasuk}`;

      if (item.keterangan === "Absen Keluar & Masuk") {
        console.log("masuk");
        return Promise.all([
          updatePresensi(url),
          updatePresensi(urlMasuk).catch((error) => {
            setError(true);
            console.log(error);
          }),
        ]);
      } else {
        return updatePresensi(url).then((res) => console.log(res));
      }
    });

    Promise.all(promises)
      .then(() => setToggle(!toggle))
      .catch((error) => {
        setError(true);
        console.log(error);
      });
  };

  const handleEdit = (user) => {
    const updatePresensi = (url) => {
      return axios.put(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("septoken")}`,
          },
        }
      );
    };

    const url = `https://api.septoweb.com/operator/presensi/${user.id}`;
    const urlMasuk = `https://api.septoweb.com/operator/presensi/${user.id_masuk}`;

    if (user.keterangan === "Absen Keluar & Masuk") {
      console.log("masuk");
      const promises = [
        updatePresensi(url),
        updatePresensi(urlMasuk).catch((error) => {
          setError(true);
          console.log(error);
        }),
      ];

      return Promise.all(promises)
        .then(() => setToggle(!toggle))
        .catch((error) => {
          setError(true);
          console.log(error);
        });
    } else {
      return updatePresensi(url)
        .then((res) => console.log(res))
        .then(() => setToggle(!toggle))
        .catch((error) => {
          setError(true);
          console.log(error);
        });
    }
  };

  const handleTolak = (user) => {
    const updatePresensi = (url) => {
      return axios.patch(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("septoken")}`,
          },
        }
      );
    };

    const url = `https://api.septoweb.com/operator/presensi/${user.id}`;
    const urlMasuk = `https://api.septoweb.com/operator/presensi/${user.id_masuk}`;

    if (user.keterangan === "Absen Keluar & Masuk") {
      const promises = [
        updatePresensi(url),
        updatePresensi(urlMasuk).catch((error) => {
          setError(true);
          console.log(error);
        }),
      ];

      return Promise.all(promises)
        .then(() => setToggle(!toggle))
        .catch((error) => {
          setError(true);
          console.log(error);
        });
    } else {
      return updatePresensi(url)
        .then((res) => console.log(res))
        .then(() => setToggle(!toggle))
        .catch((error) => {
          setError(true);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    const Header = {
      Authorization: `Bearer ${localStorage.getItem("septoken")}`,
    };
    axios
      .get("https://api.septoweb.com/operator/presensi", { headers: Header })
      .then((res) => {
        const filteredData = res.data.filter((item) => item.status === "pending");
        setListAktifitas(filteredData);
        const groupedData = {};
        filteredData.forEach((item) => {
          const key = item.user_id + "-" + new Date(item.waktu).toISOString().slice(0, 10);
          if (!groupedData[key]) {
            groupedData[key] = [];
          }
          groupedData[key].push(item);
        });

        const mergedData = [];
        Object.values(groupedData).forEach((group) => {
          const keluarEntry = group.find((item) => item.keterangan === "Absen Keluar");
          const masukEntry = group.find((item) => item.keterangan === "Absen Masuk");

          if (keluarEntry && masukEntry) {
            const mergedItem = {
              id: keluarEntry.id,
              id_masuk: masukEntry.id,
              user_id: keluarEntry.user_id,
              waktu: keluarEntry.waktu,
              keterangan: "Absen Keluar & Masuk",
              imageurl: keluarEntry.imageurl,
              lokasi: keluarEntry.lokasi,
              status: "pending",
              keterlambatan: "Tepat Waktu",
              nama: keluarEntry.nama,
              no_nip: keluarEntry.no_nip,
              email: keluarEntry.email,
              role: keluarEntry.role,
            };
            mergedData.push(mergedItem);
          } else if (masukEntry) {
            mergedData.push(masukEntry);
          }
        });

        const { columns, rows } = authorsTableData(mergedData, handleEdit, handleTolak);
        setColumns(columns);
        setRows(rows);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [toggle]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="dark">
                  Data Kehadiran
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {listAktifitas.length > 0 && (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted
                    entriesPerPage
                    showTotalEntries
                    canSearch
                    canAllClick={handleKirimAll}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
