import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const getLoginUrl = (type) => {
    switch (type) {
      case "pjlp":
        return "https://api.septoweb.com/auth/login/pjlp";
      default:
        return "https://api.septoweb.com/auth/login/nip";
    }
  };

  const login = async (identifier, password, type) => {
    try {
      const url = getLoginUrl(type);
      const response = await axios.post(url, {
        identifier,
        password,
      });

      if (!response.data.error) {
        localStorage.setItem("septoken", response.data.token);
        await fetchUserData();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error during login:", error.message);
    }
  };

  const logout = () => {
    localStorage.removeItem("septoken");
    setUser(null);
    setIsAuthenticated(false);
  };

  const checkAuth = async () => {
    const token = localStorage.getItem("septoken");
    if (token) {
      await fetchUserData();
    } else {
      setUser(null);
      setIsAuthenticated(false);
      setLoading(false);
    }
  };

  const fetchUserData = async () => {
    try {
      const header = {
        Authorization: `Bearer ${localStorage.getItem("septoken")}`,
      };

      const response = await axios.get("https://api.septoweb.com/user", { headers: header });

      setUser(response.data);
      setIsAuthenticated(true);
      setLoading(false);
    } catch (error) {
      setUser(null);
      localStorage.removeItem("septoken");
      console.error("Error fetching user data:", error.response?.data?.message || "Unknown error");
      setIsAuthenticated(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await checkAuth();
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated, checkAuth, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(AuthContext);
