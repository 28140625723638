import React, { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import { saveAs } from "file-saver";

// axios
import axios from "axios";

// Web Absensi components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";

// Web Absensi example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import authorsTableData from "layouts/ppk/data/tinjauanData";
import dataPjlp from "layouts/dataPjlp/data/dataPjlp";
import { Menu } from "@mui/material";

import { useAuth } from "../../authContext";

function Tables() {
  const [listDataBaru, setListData] = useState([]);
  const [listPjlp, setListPjlp] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [columnsPjlp, setColumnsPjlp] = useState([]);
  const [rowsPjlp, setRowsPjlp] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [editedRows, setEditedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mulaiDate, setMulaiDate] = useState("");
  const [sampaiDate, setSampaiDate] = useState("");

  const handleEditClose = () => {
    setEditOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const header = {
          Authorization: `Bearer ${localStorage.getItem("septoken")}`,
        };

        const responsePjlp = await axios.get("https://api.septoweb.com/admin/pjlp", {
          headers: header,
        });

        setListPjlp(responsePjlp.data);

        const { columnsPjlp, rowsPjlp } = dataPjlp(responsePjlp.data, handleEditOpen);

        setColumnsPjlp(columnsPjlp);
        setRowsPjlp(rowsPjlp);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async () => {
    if (listDataBaru.length > 0) {
      try {
        const header = {
          Authorization: `Bearer ${localStorage.getItem("septoken")}`,
        };

        const response = await axios.delete(
          `https://api.septoweb.com/admin/data/${listDataBaru[0].user_id}?startDate=${mulaiDate}&endDate=${sampaiDate}`,
          {
            headers: header,
          }
        );

        setEditOpen(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setEditOpen(false);
      }
    } else {
      console.log("tidak ada");
      setEditOpen(false);
    }
  };

  const handleEditOpen = async (user) => {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("septoken")}`,
    };

    const response1 = await axios.get("https://api.septoweb.com/admin/ketidakhadiran", {
      headers: header,
    });

    const filteredData1 = response1.data.filter((item) => item.status === "tersubmit");

    const response2 = await axios.get("https://api.septoweb.com/admin/presensi", {
      headers: header,
    });

    const filteredData2 = response2.data.filter((item) => item.status === "tersubmit");

    const response3 = await axios.get("https://api.septoweb.com/admin/aktifitas", {
      headers: header,
    });

    const filteredData3 = response3.data.filter((item) => item.status === "tersubmit");

    const combinedData = [...filteredData1, ...filteredData2, ...filteredData3];

    const { columns, rows } = authorsTableData(
      combinedData.filter((item) => item.no_nip === user.no_nip)
    );

    setListData(combinedData.filter((item) => item.no_nip === user.no_nip));
    setColumns(columns);
    setEditedRows(rows);
    setEditOpen(true);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="dark">
                  Data Tinjauan
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {listPjlp.length > 0 && (
                  <DataTable
                    table={{ columns: columnsPjlp, rows: rowsPjlp }}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    canSearch
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        <Dialog
          open={editOpen}
          onClose={handleEditClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">Delete Data</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <TextField
                id="date"
                label="Mulai"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ mb: 2, mt: 2 }}
                onChange={(e) => setMulaiDate(e.target.value)}
              />
              <TextField
                id="date"
                label="Sampai"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ mb: 2 }}
                onChange={(e) => setSampaiDate(e.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="dark">
              Cancel
            </Button>
            <Button color="dark" onClick={handleDelete}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
