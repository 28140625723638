/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Web Absensi components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import { Typography } from "@mui/material";

export default function data(data, handleEditOpen) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title }) => <Typography variant="h6">{title}</Typography>;

  const formatDate = (date) => {
    const d = new Date(date);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return d.toLocaleDateString(undefined, options);
  };

  const rows = data.map((user) => ({
    hidden: user.nama,
    nama: <Job title={user.nama} />,
    email: <Job title={user.email} />,
    id: <Job title={user.no_nip} />,
    hari: <Job title={formatDate(user.tanggal)} />,
    waktu: <Job title={user.waktu} />,
    kegiatan: <Job title={user.kegiatan} />,
    keterangan: <Job title={user.keterangan} />,
    action: (
      <MDButton variant="gradient" color="dark" onClick={() => handleEditOpen(user)}>
        Kirim
      </MDButton>
    ),
  }));

  const columns = [
    { Header: "hidden", accessor: "hidden", align: "left", show: false },
    { Header: "nama", accessor: "nama", width: "45%", align: "left" },
    { Header: "email", accessor: "email", align: "left" },
    { Header: "id pjlp", accessor: "id", align: "left" },
    { Header: "hari", accessor: "hari", align: "left" },
    { Header: "waktu", accessor: "waktu", align: "left" },
    { Header: "kegiatan", accessor: "kegiatan", align: "center" },
    { Header: "keterangan", accessor: "keterangan", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];
  return { columns, rows };
}
