import React, { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";

// axios
import axios from "axios";

// Web Absensi components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";

// Web Absensi example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import authorsTableData from "layouts/operator/data/authorsTableData";
import { Menu } from "@mui/material";

import { useAuth } from "../../authContext";

function Tables() {
  const [toggle, setToggle] = useState(false);
  const [listAktifitas, setListAktifitas] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(false);

  const handleEdit = (user) => {
    const Header = {
      Authorization: `Bearer ${localStorage.getItem("septoken")}`,
    };

    axios
      .put(`https://api.septoweb.com/operator/ketidakhadiran/${user.id}`, {}, { headers: Header })
      .then((res) => {
        console.log(res);
        setToggle(!toggle);
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      });
  };

  const handleTolak = (user) => {
    const Header = {
      Authorization: `Bearer ${localStorage.getItem("septoken")}`,
    };

    axios
      .patch(`https://api.septoweb.com/operator/ketidakhadiran/${user.id}`, {}, { headers: Header })
      .then((res) => {
        console.log(res);
        setToggle(!toggle);
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      });
  };

  useEffect(() => {
    const Header = {
      Authorization: `Bearer ${localStorage.getItem("septoken")}`,
    };
    axios
      .get("https://api.septoweb.com/operator/ketidakhadiran", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("septoken")}`,
        },
      })
      .then((res) => {
        const filteredData = res.data.filter((item) => item.status === "pending");
        setListAktifitas(filteredData);
        const { columns, rows } = authorsTableData(filteredData, handleEdit, handleTolak);
        setColumns(columns);
        setRows(rows);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [toggle]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="dark">
                  Data Ketidakhadiran
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {listAktifitas.length > 0 && (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
