/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Web Absensi components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { Typography } from "@mui/material";

export default function data(data, handleEditOpen) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title }) => <Typography variant="h6">{title}</Typography>;

  const rowsPjlp = data.map((user) => ({
    hidden: user.nama,
    nama: <Job title={user.nama} />,
    email: <Job title={user.email} />,
    jabatan: <Job title={user.jabatan} />,
    status: (
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={user.status === 1 ? "aktif" : "tidak aktif"}
          color={user.status === 1 ? "success" : "dark"}
          variant="gradient"
          size="sm"
        />
      </MDBox>
    ),

    action: (
      <MDButton variant="gradient" color="dark" onClick={() => handleEditOpen(user)}>
        Result
      </MDButton>
    ),
  }));

  const columnsPjlp = [
    { Header: "hidden", accessor: "hidden", align: "left", show: false },
    { Header: "nama", accessor: "nama", width: "45%", align: "left" },
    { Header: "email", accessor: "email", align: "left" },
    { Header: "jabatan", accessor: "jabatan", align: "left" },
    { Header: "status", accessor: "status", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];
  return { columnsPjlp, rowsPjlp };
}
