/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Web Absensi components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import MDButton from "components/MDButton";

export default function data(data, handleEditOpen, handleTolak) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );

  const formatDate = (date) => {
    const d = new Date(date);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return d.toLocaleDateString(undefined, options);
  };

  const rows = data.map((user) => ({
    hidden: user.nama,
    hiddenId: user.id,
    nama: <Job title={user.nama} />,
    id: <Job title={user.no_nip} />,
    waktu: <Job title={formatDate(user.waktu)} />,
    alasan: <Job title={user.alasan} />,
    keterangan: <Job title={user.keterangan} />,
    action: (
      // <MDTypography
      //   component="a"
      //   href="#"
      //   variant="button"
      //   color="text"
      //   fontWeight="medium"
      //   onClick={() => handleEditOpen(user)}
      // >
      //   Kirim
      // </MDTypography>
      <MDButton variant="gradient" color="dark" onClick={() => handleEditOpen(user)}>
        Kirim
      </MDButton>
    ),
    delete: (
      <MDButton variant="gradient" color="error" onClick={() => handleTolak(user)}>
        Tolak
      </MDButton>
    ),
  }));

  const columns = [
    { Header: "hidden", accessor: "hidden", align: "left", show: false },
    { Header: "hiddenId", accessor: "hiddenId", align: "left", show: false },
    { Header: "nama", accessor: "nama", align: "left" },
    { Header: "id pjlp", accessor: "id", align: "left" },
    { Header: "waktu", accessor: "waktu", align: "left" },
    { Header: "alasan", accessor: "alasan", align: "center" },
    { Header: "keterangan", accessor: "keterangan", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
    { Header: "delete", accessor: "delete", align: "center" },
  ];
  return { columns, rows };
}
