import React, { useEffect } from "react";
import Dashboard from "layouts/dashboard";
import Pengguna from "layouts/pengguna";
import Tables from "layouts/tables";
import Absensi from "layouts/absensi";
import RiwayatAbsensi from "layouts/riwayatabsensi";
import PenggunaPJLP from "layouts/penggunapjlp";
import Aktifitas from "layouts/aktifitas";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import KetidakHadiran from "layouts/ketidakhadiran";
import KetidakHadiranPPTK from "layouts/pptk/ketidakhadiran";
import KehadiranPPTK from "layouts/pptk/kehadiran";
import AktifitasPPTK from "layouts/pptk/aktifitas";
import Tinjauan from "layouts/pptk/tinjauan";
import KetidakHadiranPPK from "layouts/ppk/ketidakhadiran";
import KehadiranPPK from "layouts/ppk/kehadiran";
import AktifitasPPK from "layouts/ppk/aktifitas";
import TinjauanPPK from "layouts/ppk/tinjauan";
import KetidakHadiranOperator from "layouts/operator/ketidakhadiran";
import KehadiranOperator from "layouts/operator/kehadiran";
import AktifitasOperator from "layouts/operator/aktifitas";
import TinjauanOperator from "layouts/operator/tinjauan";
import DataPJLP from "layouts/dataPjlp";
import MasterJam from "layouts/masterJam";

import { useAuth } from "./authContext";

import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";

import Protected from "./Protected";

const ProtectedDashboard = Protected(Dashboard, [
  "admin",
  "ppbj",
  "pptk",
  "operator",
  "ppk",
  "pjlp",
]);
const ProtectedPengguna = Protected(Pengguna, ["admin"]);
const ProtectedAbsensi = Protected(Absensi, ["pjlp"]);
const ProtectedRiwayatAbsensi = Protected(RiwayatAbsensi, ["admin", "pptk"]);
const ProtectedPenggunaPJLP = Protected(PenggunaPJLP, ["ppbj"]);
const ProtectedAktifitas = Protected(Aktifitas, ["pjlp"]);
const ProtectedKetidakHadiran = Protected(KetidakHadiran, ["pjlp"]);
const ProtectedKetidakHadiranPPTK = Protected(KetidakHadiranPPTK, ["pptk"]);
const ProtectedKehadiranPPTK = Protected(KehadiranPPTK, ["pptk"]);
const ProtectedTinjauan = Protected(Tinjauan, ["pptk"]);
const ProtectedAktifitasPPTK = Protected(AktifitasPPTK, ["pptk"]);
const ProtectedKetidakHadiranPPK = Protected(KetidakHadiranPPK, ["ppk"]);
const ProtectedKehadiranPPK = Protected(KehadiranPPK, ["ppk"]);
const ProtectedAktifitasPPK = Protected(AktifitasPPK, ["ppk"]);
const ProtectedTinjauanPPK = Protected(TinjauanPPK, ["ppk"]);
const ProtectedKetidakHadiranOperator = Protected(KetidakHadiranOperator, ["operator"]);
const ProtectedKehadiranOperator = Protected(KehadiranOperator, ["operator"]);
const ProtectedAktifitasOperator = Protected(AktifitasOperator, ["operator"]);
const ProtectedTinjauanOperator = Protected(TinjauanOperator, ["operator"]);
const ProtectedDataPJLP = Protected(DataPJLP, ["admin"]);

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <ProtectedDashboard />,
    protected: true,
    hidden: false,
    roles: ["admin", "ppbj", "pptk", "operator", "ppk", "pjlp"],
  },
  {
    type: "collapse",
    name: "Pengguna",
    key: "pengguna",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/pengguna",
    component: <ProtectedPengguna />,
    protected: true,
    hidden: false,
    roles: ["admin"],
  },
  {
    type: "collapse",
    name: "Absensi",
    key: "absensi",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/absensi",
    component: <ProtectedAbsensi />,
    protected: true,
    hidden: false,
    roles: ["pjlp"],
  },
  {
    type: "collapse",
    name: "Aktifitas",
    key: "aktifitas",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/aktifitas",
    component: <ProtectedAktifitas />,
    protected: true,
    hidden: false,
    roles: ["pjlp"],
  },
  {
    type: "collapse",
    name: "Ketidakhadiran",
    key: "ketidakhadiran",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/ketidakhadiran",
    component: <ProtectedKetidakHadiran />,
    protected: true,
    hidden: false,
    roles: ["pjlp"],
  },
  {
    type: "collapse",
    name: "Tinjauan",
    key: "tinjauan",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/pptk/tinjauan",
    component: <ProtectedTinjauan />,
    protected: true,
    hidden: false,
    roles: ["pptk"],
  },
  // {
  //   type: "collapse",
  //   name: "Kehadiran",
  //   key: "kehadiranpptk",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/pptk/kehadiran",
  //   component: <ProtectedKehadiranPPTK />,
  //   protected: true,
  //   hidden: false,
  //   roles: ["pptk"],
  // },
  // {
  //   type: "collapse",
  //   name: "Ketidakhadiran",
  //   key: "ketidakhadiranpptk",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/pptk/ketidakhadiran",
  //   component: <ProtectedKetidakHadiranPPTK />,
  //   protected: true,
  //   hidden: false,
  //   roles: ["pptk"],
  // },
  // {
  //   type: "collapse",
  //   name: "Aktifitas",
  //   key: "aktifitaspptk",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/pptk/aktifitas",
  //   component: <ProtectedAktifitasPPTK />,
  //   protected: true,
  //   hidden: false,
  //   roles: ["pptk"],
  // },
  {
    type: "collapse",
    name: "Pjlp",
    key: "pjlp",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/pjlp",
    component: <ProtectedPenggunaPJLP />,
    protected: true,
    hidden: false,
    roles: ["ppbj"],
  },
  {
    type: "collapse",
    name: "Kehadiran",
    key: "kehadiranppk",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/ppk/kehadiran",
    component: <ProtectedKehadiranPPK />,
    protected: true,
    hidden: false,
    roles: ["ppk"],
  },
  {
    type: "collapse",
    name: "Ketidakhadiran",
    key: "ketidakhadiranppk",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/ppk/ketidakhadiran",
    component: <ProtectedKetidakHadiranPPK />,
    protected: true,
    hidden: false,
    roles: ["ppk"],
  },

  {
    type: "collapse",
    name: "Aktifitas",
    key: "aktifitasppk",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/ppk/aktifitas",
    component: <ProtectedAktifitasPPK />,
    protected: true,
    hidden: false,
    roles: ["ppk"],
  },
  {
    type: "collapse",
    name: "Tinjauan",
    key: "tinjauanppk",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/ppk/tinjauan",
    component: <ProtectedTinjauanPPK />,
    protected: true,
    hidden: false,
    roles: ["ppk"],
  },
  {
    type: "collapse",
    name: "Ketidakhadiran",
    key: "ketidakhadiranoperator",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/operator/ketidakhadiran",
    component: <ProtectedKetidakHadiranOperator />,
    protected: true,
    hidden: false,
    roles: ["operator"],
  },
  {
    type: "collapse",
    name: "Kehadiran",
    key: "kehadiranoperator",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/operator/kehadiran",
    component: <ProtectedKehadiranOperator />,
    protected: true,
    hidden: false,
    roles: ["operator"],
  },
  {
    type: "collapse",
    name: "Riwayat Absensi",
    key: "riwayatabsensi",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/riwayatabsensi",
    component: <ProtectedRiwayatAbsensi />,
    protected: true,
    hidden: false,
    roles: ["admin", "pptk"],
  },
  {
    type: "collapse",
    name: "Data PJLP",
    key: "datapjlp",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/datapjlp",
    component: <ProtectedDataPJLP />,
    protected: true,
    hidden: false,
    roles: ["admin"],
  },
  {
    type: "collapse",
    name: "Master Jam",
    key: "master-jam",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/master-jam",
    component: <MasterJam />,
    protected: true,
    hidden: false,
    roles: ["admin"],
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/login",
    component: <SignIn />,
    protected: false,
    hidden: true,
  },
];

export default routes;
